<script setup lang="ts">
import { useField } from "vee-validate";

interface Props {
  modelValue?: string;
  label?: string;
  name: string;
  id?: string;
  placeholder?: string;
  validateAs?: string;
  disabled?: boolean;
  onInput?: boolean;
  inputKey?: string | number;
  autofocus?: boolean;
  autocomplete?: string;
  ariaLabel?: string;
  rows?: string;
  cols?: string;
  maxLength?: string | number;
  showCount?: boolean;
}

interface Emit {
  (event: "update:modelValue", value: string): void;
}

defineEmits<Emit>();
const props = withDefaults(defineProps<Props>(), {
  autocomplete: "off",
  rows: "1",
  cols: "30",
  maxLength: "2500",
});

const input = ref();
const { value, errorMessage } = useField<string>(() => props.name, props.validateAs, {
  syncVModel: true,
});
const textLength = ref(props.modelValue?.length ?? 0);

const adjustTextarea = (event: Event) => {
  if ( event.target) textLength.value = (event.target as HTMLTextAreaElement).value.length;
  if (input.value.scrollHeight > 100) return;
  input.value.style.height = "auto";
  input.value.style.height = `${input.value.scrollHeight}px`;
};

onMounted(() => {
  if (props.autofocus && input.value) input.value.focus();
});

defineExpose({
  focus: () => {
    if (input.value) input.value.focus();
  },
});
</script>

<template>
  <div class="group" :class="{ 'opacity-50 select-none pointer-events-none cursor-default': disabled }">
    <label v-if="label" :for="id" class="px-1 text-xs group-focus-within:text-primary">{{ label }}</label>
    <div class="relative" :class="{'mb-3': showCount}">
      <textarea
        :id="id"
        ref="input"
        @input="adjustTextarea"
        :aria-label="ariaLabel"
        :key="inputKey"
        v-model.trim="value"
        :name="name"
        :disabled="disabled"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        autoResize
        :rows="rows"
        :cols="cols"
        :maxlength="maxLength"
        class="h-form-input pl-2"
        :class="{ 'border-danger': errorMessage }"
      />
      <div v-if="showCount" class="absolute right-1 -mt-1">
        <div class="bg-primary self-end rounded-b-lg px-2 text-white min-w-">
          <div class="flex items-center justify-center gap-1 text-xs">
            <div>{{ textLength }}</div>
            <div>/</div>
            <div>{{ maxLength }}</div>
          </div>
        </div>
      </div>
    </div>

    <InputError v-show="errorMessage">{{ errorMessage }}</InputError>
  </div>
</template>

<style>
textarea {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

textarea::-webkit-scrollbar {
  display: none;
}
</style>
